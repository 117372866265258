<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Anúncios</v-toolbar-title>
                    <v-spacer/>
                    <!-- <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/> -->
                    <PeriodoFilter
                        v-model="periodo"
                        defaultPeriodo="Este mês"
                        @update="consultar"
                    />
                    <v-btn color="primary" fab small @click="openCadastro">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 180"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                        >
                            <template v-slot:item.conversao_trials="{ item }">
                                {{ item.conversao_trials }}%
                            </template>
                            <template v-slot:item.conversao_clientes="{ item }">
                                {{ item.conversao_clientes }}%
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <Anuncio
                    :visible="visible"
                    :formData="form"
                    @onCadastro="onCadastro"
                    @close="close"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import PeriodoFilter from '@/components/PeriodoFilter';
import Anuncio from './Anuncio';

export default {
    name: 'Anuncios',

    components: {
        BaseContainer,
        PeriodoFilter,
        Anuncio,
    },

    data: () => ({
        form: {},
        periodo: {},
        search: '',
        visible: false,
        loading: false,
        dados: [],
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Nome', value: 'nome' },
            { text: 'UUID', value: 'uuid' },
            { text: 'Leads', value: 'contatos' },
            { text: 'Trials', value: 'trials' },
            { text: 'Clientes', value: 'clientes' },
            { text: 'Conversão Trials', value: 'conversao_trials' },
            { text: 'Conversão Clientes', value: 'conversao_clientes' },
        ],
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        getParams() {
            const params = new URLSearchParams();

            params.append('start', this.periodo.start);
            params.append('end', this.periodo.end);

            // params.append('q', this.search || '');
            // params.append('page', this.pagination.current_page);

            // if (this.options.sortBy.length) {
            //     params.append('sortField', this.options.sortBy[0]);
            // }
            // if (this.options.sortDesc.length) {
            //     params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            // }

            return params.toString();
        },

        consultar() {
            this.loading = true;
            this.dados = [];

            const params = this.getParams();

            this.$http.get(`anuncios?${params}`).then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        close() {
            this.visible = false;
            this.form = {};
        },

        openCadastro() {
            this.form = {};
            this.visible = true;
        },

        onCadastro() {
            this.close();
            this.consultar();
        },

        editar(row) {
            this.form = row;
            this.visible = true;
        },
    },
}
</script>
